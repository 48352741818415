import { createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPost } from '../../app/api';
import qs from "qs";
import {serialize} from "../../app/utils";

export const URL_PREFIX = '/web/site';

export const webPageSlice = createSlice({
    name: 'webPage',
    initialState: {
        isSubmitting: false,
        lastError: null,
        itemsRequireUpdate: true,
        pagesList: {
            count: 0,
            items: []
        },
        pageItem: null
    },
    reducers: {
        loading: (state, action) => {
            state.isSubmitting = true;
            state.lastError = null;
        },
        setError: (state, action) => {
            state.isSubmitting = false;
            state.lastError = action.payload;
        },
        itemsRequireUpdate: (state) => {
            state.itemsRequireUpdate = true;
        },
        setPagesList: (state, action) => {
            state.itemsRequireUpdate = false;
            state.isSubmitting = false;
            state.pagesList = action.payload;
        },
        setPageItem: (state, action) => {
            state.itemsRequireUpdate = false;
            state.isSubmitting = false;
            state.pageItem = action.payload;
        },
    }
});

export const { loading, setError, itemsRequireUpdate, setPagesList, setPageItem } = webPageSlice.actions;

export const selectLoadingState = state => ({
    isSubmitting: state.webPage.isSubmitting,
    lastError: state.webPage.lastError
});
export const selectItemsNeedUpdate = state => state.webPage.itemsRequireUpdate;
export const selectPagesList = state => state.webPage.pagesList;
export const selectPageItem = state => state.webPage.pageItem;


export const getPageList = (site_id, page, limit) => dispatch => {
    let query = serialize({ page, limit });

    dispatch(loading());
    return apiGet(`${URL_PREFIX}/${site_id}/page/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setPagesList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getPage = (site_id, id) => dispatch => {
    dispatch(loading());
    return apiGet(`${URL_PREFIX}/${site_id}/page/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setPageItem(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const updatePage = (site_id, id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`${URL_PREFIX}/${site_id}/page/${id}`, data, 'PUT')
        .then(result => {
            if (result && result._id) {
                dispatch(setPageItem(result));
                dispatch(itemsRequireUpdate());
                return result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const deletePage = (site_id, id) => dispatch => {
    dispatch(loading());
    return apiPost(`${URL_PREFIX}/${site_id}/page/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setPageItem(null));
                dispatch(itemsRequireUpdate());
                return true;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const createPage = (site_id, data) => dispatch => {
    dispatch(loading());
    return apiPost(`${URL_PREFIX}/${site_id}/page/`, data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setPageItem(result.result));
                dispatch(itemsRequireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return new Error(result.error);
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export default webPageSlice.reducer;