import { createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPost } from '../../app/api';
import qs from "qs";
import {serialize} from "../../app/utils";

export const URL_PREFIX = '/web/content';
export const SITE_CACHE_TIME = 60000;

export const contentSlice = createSlice({
    name: 'content',
    initialState: {
        isSubmitting: false,
        lastError: null,
        itemsRequireUpdate: true,
        list: {
            count: 0,
            items: []
        },
        item: null
    },
    reducers: {
        loading: (state, action) => {
            state.isSubmitting = true;
            state.lastError = null;
        },
        setError: (state, action) => {
            state.isSubmitting = false;
            state.lastError = action.payload;
        },
        itemsRequireUpdate: (state) => {
            state.itemsRequireUpdate = true;
        },
        setList: (state, action) => {
            state.itemsRequireUpdate = false;
            state.isSubmitting = false;
            state.list = action.payload;
        },
        setItem: (state, action) => {
            state.itemsRequireUpdate = false;
            state.isSubmitting = false;
            state.item = action.payload;
        },
    }
});

export const { loading, setError, itemsRequireUpdate, setList, setItem } = contentSlice.actions;

export const selectLoadingState = state => ({
    isSubmitting: state.content.isSubmitting,
    lastError: state.content.lastError
});
export const selectContentItemsNeedUpdate = state => state.content.itemsRequireUpdate;
export const selectContentList = state => state.content.list;
export const selectContentItem = state => state.content.item;
export const selectContentStatus = state => state.content.item ? state.content.item.status : null;

export async function getLinksSuggestion(search, { site_id }) {
    let query = qs.stringify({ search, filter: { site_id } });

    try {
        let result = await apiGet(`/web/content/_suggestLinks${query ? ('?' + query) : ''}`);
        return result.items;
    } catch(err) {
        return [{"name":"Ошибка", "title": err ? (err.message || err) : 'Неизвестная ошибка'}];
    }
};

export const loadContentList = (page, limit, filter = {}) => dispatch => {
    let query = qs.stringify({ page, limit, filter });

    dispatch(loading());
    return apiGet(`${URL_PREFIX}/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const loadContent = (id) => dispatch => {
    dispatch(loading());
    return apiGet(`${URL_PREFIX}/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setItem(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const loadContentPreview = (id) => dispatch => {
    return apiGet(`${URL_PREFIX}/${id}/_prepare`)
        .then(result => {
            return result;
        })
        .catch(err => {
            return new Error(err);
        });
};

export const buildPagePreview = (id) => dispatch => {
    return apiGet(`${URL_PREFIX}/${id}/_build`)
        .then(result => {
            return result;
        })
        .catch(err => {
            return new Error(err);
        });
};

export const publishContent = (id) => dispatch => {
    return apiGet(`${URL_PREFIX}/${id}/_publish`)
        .then(result => {
            return result;
        })
        .catch(err => {
            return new Error(err);
        });
};

export const copyContent = (id) => dispatch => {
    return apiGet(`${URL_PREFIX}/${id}/_copy`)
        .then(result => {
            return result;
        })
        .catch(err => {
            return new Error(err);
        });
};

export const updateContent = (id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`${URL_PREFIX}/${id}`, data, 'PUT')
        .then(result => {
            if (result && result._id) {
                dispatch(setItem(result));
                dispatch(itemsRequireUpdate());
                return result;
            } else {
                dispatch(setError(result.error));
                return new Error(result.error);
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const deleteContent = (id) => dispatch => {
    dispatch(loading());
    return apiPost(`${URL_PREFIX}/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setItem(null));
                dispatch(itemsRequireUpdate());
                return true;
            } else {
                dispatch(setError(result.error));
                return new Error(result.error);
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const createContent = (data) => dispatch => {
    dispatch(loading());
    return apiPost(`${URL_PREFIX}`, data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setItem(result.result));
                dispatch(itemsRequireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return new Error(result.error);
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export default contentSlice.reducer;