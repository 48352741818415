export const CRM_URL = '/crm';
export const CRM_IN_DEALS = `${CRM_URL}/in-deals`;
export const CRM_OUT_DEALS = `${CRM_URL}/out-deals`;
export const CRM_PANE_DEAL_ITEM = "p-deal";
export const CRM_MODAL_ADD_DEAL = "m-add-deal";
export const CRM_SUBPANE_DEAL_ITEM_FEED = "p-deal-feed";
export const CRM_SUBPANE_DEAL_ITEM_PRODUCT = "p-deal-product";

export const CRM_CONTACTS = `${CRM_URL}/contacts`;
export const CRM_PANE_CONTACT_ITEM = "p-contact";
export const CRM_SUBPANE_CONTACT_ITEM_STAFF = "p-contact-staff";
export const CRM_MODAL_ADD_CONTACT = "m-add-contact";

export const CRM_TABS_MENU = [
    {label: 'Обзор', icon: 'pi pi-fw pi-home', url: CRM_URL},
    {label: 'Входящие сделки', icon: 'pi pi-fw pi-dollar', url: CRM_IN_DEALS},
    {label: 'Исходящие сделки', icon: 'pi pi-fw pi-money-bill', url: CRM_OUT_DEALS},
    {label: 'Контакты', icon: 'pi pi-fw pi-id-card', url: CRM_CONTACTS}
];

export const CRM_SIDEBAR_MENU = {
    label:'CRM',
    icon:'pi pi-fw pi-sort-amount-down',
    url: CRM_URL,
    items:[
        {
            label:'Входящие сделки',
            icon:'pi pi-fw pi-dollar',
            url: CRM_IN_DEALS
        },
        {
            label:'Исходящие сделки',
            icon:'pi pi-fw pi-money-bill',
            url: CRM_OUT_DEALS
        },
        {
            label:'Контакты',
            icon:'pi pi-fw pi-id-card',
            url: CRM_CONTACTS
        },
        { separator:true },
        {
            label:'Создать',
            icon:'pi pi-fw pi-plus',
            items: [
                {
                    label:'Сделку',
                    icon:'pi pi-fw pi-dollar',
                    url: `#${CRM_MODAL_ADD_DEAL}`
                },
                {
                    label:'Контакт',
                    icon:'pi pi-fw pi-id-card',
                    url: `#${CRM_MODAL_ADD_CONTACT}`
                }
            ]
        }/*,
        {
            label:'Найти',
            icon:'pi pi-fw pi-search'
        }*/
    ]
};

export const CONTACT_TYPE = [
    {name: "Физ. лицо", value: "Person"},
    {name: "ИП", value: "Entrepreneur"},
    {name: "Копания", value: "Company"}
];

export const DEAL_INCOMING_STATUS = [
    {name:"Лид", id: 'new', color: 'new'},
    {name:"В работе", id: 'progress', color: 'progress'},
    {name:"Приостановлена", id: 'wait', color: 'wait'},
    {name:"Отменена", id: 'canceled', color: 'canceled'},
    {name:"Завершена", id: 'success', color: 'success'},
    {name:"На обслуживании", id: 'continue', color: 'continue'}
];

export const DEAL_OUTGOIN_STATUS = [
    {name:"Запрос", id: 'new', color: 'new'},
    {name:"Согласование", id: 'progress', color: 'progress'},
    {name:"Ожидает доставки", id: 'wait', color: 'wait'},
    {name:"Отменена", id: 'canceled', color: 'canceled'},
    {name:"Завершена", id: 'success', color: 'success'},
    {name:"На обслуживании", id: 'continue', color: 'continue'}
];

export const DEAL_PAYMENT_STATUS = [
    {name:"Не оплачена", id: 'no', color: 'no'},
    {name:"Ожидает оплаты", id: 'await', color: 'await'},
    {name:"Частично оплачена", id: 'partial', color: 'partial'},
    {name:"Оплачена", id: 'complete', color: 'complete'}
];