import { createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPost } from '../../app/api';
import qs from "qs";

export const caseSlice = createSlice({
    name: 'case',
    initialState: {
        isSubmitting: false,
        lastError: null,
        casesRequireUpdate: true,
        caseList: {
            count: 0,
            items: []
        },
        caseItem: null,
    },
    reducers: {
        loading: (state, action) => {
            state.isSubmitting = true;
            state.lastError = null;
        },
        setError: (state, action) => {
            state.isSubmitting = false;
            state.lastError = action.payload;
        },
        casesRequireUpdate: (state) => {
            state.categoriesRequireUpdate = true;
        },
        setCaseList: (state, action) => {
            state.categoriesRequireUpdate = false;
            state.isSubmitting = false;
            state.caseList = action.payload;
        },
        setCaseItem: (state, action) => {
            state.categoriesRequireUpdate = false;
            state.isSubmitting = false;
            state.caseItem = action.payload;
        },
    }
});

export const { loading , setError, casesRequireUpdate, setCaseList, setCaseItem } = caseSlice.actions;

export const selectLoadingState = state => ({
    isSubmitting: state.case.isSubmitting,
    lastError: state.case.lastError
});

export const selectCaseList = state => state.case.caseList;
export const selectCaseItem = state => state.case.caseItem;
export const selectCaseNeedToUpdate = state => state.case.casesRequireUpdate;

export const createCase = (data) => dispatch => {
    dispatch(loading());
    return apiPost('/catalog/case', data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setCaseItem(result.result));
                dispatch(casesRequireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const checkCase = (id) => {
    return apiGet(`/catalog/case/${id}`)
        .then(result => {
            if (result._id) {
                return result;
            } else {
                return null;
            }
        })
        .catch(err => {});
};

export const getCase = (id) => dispatch => {
    dispatch(loading());
    return apiGet(`/catalog/case/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setCaseItem(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const loadCaseList = (page, limit) => dispatch => {
    let query = qs.stringify({ page, limit, filter: {} });

    dispatch(loading());
    return apiGet(`/catalog/case/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setCaseList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const updateCaseSilent = (id, data)  => {
    //dispatch(loading());
    return apiPost(`/catalog/case/${id}`, data, 'PUT', true, ['category', 'size', 'variant', 'product'])
        .then(result => {
            if (result && result._id) {
                return result._id;
            } else {
                return null;
            }
        })
        .catch(err => {
        });
};

export const updateCase = (id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`/catalog/case/${id}`, data, 'PUT', true, ['category', 'size', 'variant', 'product'])
        .then(result => {
            if (result && result._id) {
                dispatch(setCaseItem(result));
                dispatch(casesRequireUpdate());
                return result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const deleteCase = (id) => dispatch => {
    dispatch(loading());
    return apiPost(`/catalog/case/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setCaseItem(null));
                dispatch(casesRequireUpdate());
                return true;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export default caseSlice.reducer;