import React from "react";
import {Route, Switch} from "react-router-dom";

const AuthModule = React.lazy(() => import('./pages/Auth'));
const InternalRouter = React.lazy(() => import('./InternalRouter'));
const TestPage = React.lazy(() => import('./pages/Test'));

export default function Router() {
    return (<Switch>
    	<Route path="/auth" component={AuthModule} />
        <Route path="/__test" component={TestPage} />
    	<Route path="*" component={InternalRouter}/>
    </Switch>);
}