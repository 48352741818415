import React from 'react';
import classNames from 'classnames';
import {Centered} from "../layout/centered/Centered";
import styles from "./styles.module.scss";

export const LoadingScreen = (props) => {
	return (<Centered rootClassName={styles.container} className={classNames("pageloader", {
		'is-active': props.active
	}, props.className)}>
			<h2 className="title is-uppercase">{props.message}</h2>
			<span className={styles.loader}>
				<i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i>
			</span>
		</Centered>);
};