import { createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPost } from '../../app/api';
import qs from "qs";
import {serialize, Hash, pruneEmpty, buildTreeModel} from "../../app/utils";
import {CRM_SUBPANE_DEAL_ITEM_PRODUCT} from "./index";

export const crmSlice = createSlice({
    name: 'crm',
    initialState: {
        isSubmitting: false,
        lastError: null,
        profilesRequireUpdate: true,
        profileList: {
            count: 0,
            items: []
        },
        profilePane: null,
        profileSubPane: null,
        inDealsRequireUpdate: true,
        inDealList: {
            count: 0,
            items: []
        },
        outDealsRequireUpdate: true,
        outDealList: {
            count: 0,
            items: []
        },
        dealPane: null,
        dealSubPane: null,
    },
    reducers: {
        loading: (state, action) => {
            state.isSubmitting = true;
            state.lastError = null;
        },
        setError: (state, action) => {
            state.isSubmitting = false;
            state.lastError = action.payload;
        },
        setProfileList: (state, action) => {
            state.profilesRequireUpdate = false;
            state.isSubmitting = false;
            state.profileList = action.payload;
        },
        setProfilePane: (state, action) => {
            state.profilesRequireUpdate = false;
            state.isSubmitting = false;
            state.profilePane = action.payload;
        },
        setProfileSubPane: (state, action) => {
            state.profilesRequireUpdate = false;
            state.isSubmitting = false;
            state.profileSubPane = action.payload;
        },
        profilesRequireUpdate: (state) => {
            state.profilesRequireUpdate = true;
        },
        setInDealList: (state, action) => {
            state.inDealsRequireUpdate = false;
            state.isSubmitting = false;
            state.inDealList = action.payload;
        },
        setOutDealList: (state, action) => {
            state.outDealsRequireUpdate = false;
            state.isSubmitting = false;
            state.outDealList = action.payload;
        },
        setDealPane: (state, action) => {
            state.inDealsRequireUpdate = false;
            state.outDealsRequireUpdate = false;
            state.isSubmitting = false;
            state.dealPane = action.payload;
        },
        setDealSubPane: (state, action) => {
            state.inDealsRequireUpdate = false;
            state.outDealsRequireUpdate = false;
            state.isSubmitting = false;
            state.dealSubPane = action.payload;
        },
        dealsRequireUpdate: (state) => {
            state.inDealsRequireUpdate = true;
            state.outDealsRequireUpdate = true;
        }
    }
});

export const { loading, setError, 
    setProfileList, setProfilePane, setProfileSubPane, profilesRequireUpdate,
    setInDealList, setOutDealList, setDealPane, setDealSubPane, dealsRequireUpdate
} = crmSlice.actions;

export const selectLoadingState = state => ({
    isSubmitting: state.crm.isSubmitting,
    lastError: state.crm.lastError
});
export const selectProfileList = state => state.crm.profileList;
export const selectProfilePane = state => state.crm.profilePane;
export const selectProfileNeedUpdate = state => state.crm.profilesRequireUpdate;
export const selectProfileSubPane = state => state.crm.profileSubPane;
export const selectInDealList = state => state.crm.inDealList;
export const selectOutDealList = state => state.crm.outDealList;
export const selectDealPane = state => state.crm.dealPane;
export const selectInDealNeedUpdate = state => state.crm.inDealsRequireUpdate;
export const selectOutDealNeedUpdate = state => state.crm.outDealsRequireUpdate;
export const selectDealSubPane = state => state.crm.dealSubPane;


export async function getContactSuggestion(search, { type, workin }) {
    let query = qs.stringify({ search, filter: { type, workin } });

    try {
        let result = await apiGet(`/crm/contact/${query ? ('?' + query) : ''}`);
        return result.items;
    } catch(err) {
        return [{"name":"Ошибка", "title": err ? (err.message || err) : 'Неизвестная ошибка'}];
    }
};

export const getContactList = (page, limit, {search, ...filter}) => dispatch => {
    let queryFilter = pruneEmpty(filter);
    let query = qs.stringify({ page, limit, search, filter: queryFilter});
    //let query = serialize({ page, limit });

    dispatch(loading());
    return apiGet(`/crm/contact/${query ? ('?'+query): ''}`, 3000)
        .then(result => {
            if (result.items) {
                dispatch(setProfileList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getContact = (id) => dispatch => {
    dispatch(loading());
    return apiGet(`/crm/contact/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setProfilePane(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const updateContact = (id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`/crm/contact/${id}`, data, 'PUT')
        .then(result => {
            if (result && result._id) {
                dispatch(setProfilePane(result));
                dispatch(profilesRequireUpdate());
                return result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const deleteContact = (id) => dispatch => {
    dispatch(loading());
    return apiPost(`/crm/contact/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setProfilePane(null));
                dispatch(profilesRequireUpdate());
                Hash.clear();
                return true;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const addNewContact = (data) => dispatch => {
    dispatch(loading());
    return apiPost('/crm/contact', data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setProfilePane(result.result));
                dispatch(profilesRequireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getInDealList = (page, limit, filter) => dispatch => {
    let addFilter = pruneEmpty(filter);
    if (addFilter.status) addFilter.status = [addFilter.status];
    if (addFilter.payment) addFilter.payment = [addFilter.payment];
    let query = qs.stringify({ page, limit, filter: {
        ...addFilter,
        type: ['IncomingDeal']
    }});

    dispatch(loading());
    return apiGet(`/crm/deal/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setInDealList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getOutDealList = (page, limit, filter) => dispatch => {
    let addFilter = pruneEmpty(filter);
    if (addFilter.status) addFilter.status = [addFilter.status];
    if (addFilter.payment) addFilter.payment = [addFilter.payment];
    let query = qs.stringify({ page, limit, filter: {
        ...addFilter,
        type: ['OutgoingDeal']
    }});

    dispatch(loading());
    return apiGet(`/crm/deal/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setOutDealList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getDeal = (id) => dispatch => {
    dispatch(loading());
    return apiGet(`/crm/deal/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setDealPane(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getDealProducts = (id) => {
    return apiGet(`/crm/deal/${id}`)
        .then(result => {
            if (result._id) {
                return buildTreeModel(result.overrides.map((item, index) => {
                    let product = result.contain.filter(it => it._id === item._id).pop();
                    return {
                        _id: item._id,
                        index: index,
                        url: Hash.link(CRM_SUBPANE_DEAL_ITEM_PRODUCT, {
                            id: result._id,
                            n: index
                        }),
                        level: item.level,
                        name: product.name,
                        category: product.title.split(" > ").slice(0, -1),
                        method: product.price.method,
                        unit: product.price.unit,
                        statusList: product.statuses,
                        amount: item.amount,
                        cost: item.cost,
                        total: item.total,
                        price: item.price,
                        status: item.status,
                        __type: 'product'
                    };
                }));
            } else {
                return [];
            }
        });
};

export const updateDeal = (id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`/crm/deal/${id}`, data, 'PUT')
        .then(result => {
            if (result && result._id) {
                dispatch(setDealPane(result));
                dispatch(dealsRequireUpdate());
                return result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const deleteDeal = (id) => dispatch => {
    dispatch(loading());
    return apiPost(`/crm/deal/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setDealPane(null));
                dispatch(dealsRequireUpdate());
                Hash.clear();
                return true;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const createDeal = (data) => dispatch => {
    dispatch(loading());
    return apiPost('/crm/deal', data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setDealPane(result.result));
                dispatch(dealsRequireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export default crmSlice.reducer;