import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import crmReducer from '../features/crm/crmSlice';
import catalogReducer from '../features/catalog/catalogSlice';
import caseReducer from "../features/case/caseSlice";
import webReducer from "../features/web/webSlice";
import webPageReducer from "../features/web/webPageSlice";
import webWidgetReducer from "../features/web/webWidgetSlice";
import orderReducer from "../features/order/orderSlice";
import contentReducer from "../features/content/contentSlice";

import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

export default configureStore({
  reducer: {
    auth: authReducer,
    crm: crmReducer,
    catalog: catalogReducer,
    case: caseReducer,
    web: webReducer,
    webPage: webPageReducer,
    webWidget: webWidgetReducer,
    order: orderReducer,
    content: contentReducer
  }
});
