import * as momentJS from 'moment';
import 'moment/locale/ru';
import QRCode from 'qrcode';
import _ from "lodash"
import {useEffect, useState} from "react";

momentJS.locale('ru');
export const moment = momentJS;

export const makeURI = (obj) => obj ? ("?" + Object.keys(obj).map(key => [encodeURIComponent(key), encodeURIComponent(obj[key])].join("=")).join("&")) : "";

export const getQR = async text => {
  try {
    return await QRCode.toDataURL(text);
  } catch (err) {
    return null;
  }
}

export const extractBlocks = (source) => [...source.matchAll(/\{\{\{([\a-zA-Z0-9\_\.]+)\}\}\}/ig)].map(([,key]) => key);

export const declOfNum = (number, titles) => {  
    number = Math.abs(number);
    let cases = [2, 0, 1, 1, 1, 2];  
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
};

export const getDisabled = bool => (bool ? {disabled: 'disabled'} : {});

export function trampoline(fn) {
  var op = fn;
  while (op != null && typeof op === 'function') {
    op = op();
  }
  return op;
};

export const wrapPromise = (promise) => {
  let status = "pending";
  let result;
  let suspender = promise.then(
    r => {
      status = "success";
      result = r;
    },
    e => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
};

export class Timer {
	constructor(time) {
		this.time = time;
		this.timeout = null;
	}
	wait() {
		return new Promise(resolve => {
			this.timeout = setTimeout(resolve, Timer.getTimeout(this.time));
			console.log(`t: `, this.timeout);
		});
	}
	cancel() {
		clearTimeout(this.timeout);
	}
	static getTimeout(time) {
		let type = time.split("").pop();
	    let dur = parseInt(time);
	    switch (type) {
	        case "s": return dur * 1000;
	        case "m": return dur * 60 * 1000;
	        case "h": return dur * 60 * 60 * 1000;
	        default: return dur;
	    }
	}
}

export function selectActiveItem(menu, check) {
    return menu.map(item => check(item) ? Object.assign({}, item, {active: true}) : item);
}

export class Hash {
    constructor(value) {
        this.value = value || document.location.hash;
        let [id, ...paramsTemp] = this.value.split(":");
        this.params = paramsTemp.map(s => s.split("=")).reduce((a, c) => Object.assign(a, {
            [c[0]]: c[1]
        }), {});
        this.id = (id.length > 1) ? id.slice(1) : null;
    }

    build() {
        if (!this.id) return "";
        else return `#${this.id}${this.getParamsString()}`;
    }

    getParamsString() {
        let p = Object.keys(this.params).map(k => `${k}=${this.params[k]}`).join(":");
        if (p.length > 0) return `:${p}`;
        else return "";
    }

    isEmpty() {
        return this.value === "" || this.value === "#";
    }

    isModal() {
        return this.id ? this.id[0] === "m" : false;
    }

    isPane() {
        return this.id ? this.id[0] === "p" : false;
    }

    isSubPane() {
        return this.id ? (this.isPane() && this.id.split("-").length === 3) : false;
    }

    stepUp() {
        if (this.isSubPane()) this.id = this.id.split("-").slice(0, -1).join("-");
        return this.build();
    }

    static isVisible(type) {
        let h = new Hash();
        if (h.isEmpty()) return false;

        switch (type) {
            case "pane": return h.isPane() || h.isSubPane();
            case "subpane": return h.isSubPane();
            case "modal": return h.isModal();
            default: return false;
        }
    }

    static isExists() {
        let h = new Hash();
        return !h.isEmpty();
    }

    static go(to, params) {
        document.location.hash = Hash.link(to, params);
    }

    static redirect(url) {
        document.location.hash = url;
    }

    static link(to, params) {
        let h = new Hash();
        h.id = to;
        h.params = params || {};
        return h.build();
    }

    static clear() {
        document.location.hash = "";
    }
}

export function useKeyPress({targetKey, onPressDown, onPressUp}) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    useEffect(() => {
        // If pressed key is our target key then set to true
        function downHandler({ key }) {
            if (key === targetKey) {
                setKeyPressed(true);
                onPressDown && onPressDown();
            }
        }

        // If released key is our target key then set to false
        const upHandler = ({ key }) => {
            if (key === targetKey) {
                setKeyPressed(false);
                onPressUp && onPressUp();
            }
        };

        // Add event listeners
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    });

    return keyPressed;
}

export function pruneEmpty(obj, keepArray = true, keepKeys = []) {
    return function prune(current) {
        _.forOwn(current, function (value, key) {
            if (keepKeys.indexOf(key) !== -1) return;
            if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value) ||
                (_.isString(value) && _.isEmpty(value)) ||
                (_.isPlainObject(value) && _.isEmpty(prune(value))) ||
                (!keepArray && _.isArray(value) && _.isEmpty(prune(value)))) {

                delete current[key];
            }
        });

        if (_.isArray(current)) _.pull(current, undefined);

        return current;

    }(_.cloneDeep(obj));  // Do not modify the original object, create a clone instead
}

export const isRequired = (name, fields) => fields && fields.indexOf(name) !== -1;

export const serialize = function(obj) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            if (obj[p] !== undefined && obj[p] !== '') {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    return str.join("&");
}

export function buildTreeModel(l, getLabel) {
    const cut = (l, v) => { let ok = false; return l.filter(n => {
        if (n.level === v) { ok = true; }
        return ok;
    }); };
    const tree = (its, lv) => {
        let ar = [];
        let [f, ...oth] = its;
        if (!f) return ar;
        let n = _.cloneDeep(f);

        let items = tree(oth, f.level + 1);
        if (items.length > 0) {
            n.items = items;
            n.__leaf = false;
        } else {
            n.__leaf = true;
        }
        if (f.level === lv) {ar = [n, ...tree(cut(oth, f.level), f.level)];}
        return ar;
    };
    return tree(l.map((it, index) => Object.assign({}, {index, ...it})), 0);
}

export const round = (n, p = 2) => { p = Math.pow(10, p); return Math.round(n*p)/p; }

export function reduceRecursive(arr, key, acc, cb, level = 0, parent) {
    let index = 0;
    return arr.reduce((a, c) => {
        a = cb(a, c, index, level, parent);
        if (c[key] && c[key].length > 0) {
            a = reduceRecursive(c[key], key, a, cb, level + 1, c);
        }
        index++;
        return a;
    }, acc)
}

export function mapRecursive(arr, key, cb, level = 0, parent) {
    let index = 0;
    return arr.map((c) => {
        c = cb(c, index, level, parent);
        if (c[key] && c[key].length > 0) {
            c[key] = mapRecursive(c[key], key, cb, level + 1, c);
        }
        index++;
        return c;
    });
}

export function getReadableFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export function download(content, fileName) {
    var a = document.createElement("a");
    var file = new Blob([JSON.stringify(content, null, 2)], {type: 'application/json'});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}

export function clearCategory(c) {
    return c ? {
        name: c.name,
        type: c.type,
        category: clearCategory(c.category)
    } : null;
}
export function clearProduct(p, u) {
    return p ? {
        name: u || p.name,
        type: p.type
    } : null;
}
export function clearSection(s) {
    let nextSection = Object.assign({}, s);
    nextSection.product = clearProduct(s.product, s.sourceURL);
    if (s.sourceURL) {
        nextSection.sourceURL = null;
        nextSection.sourceType = null;
        nextSection.sourceProvider = null;
        nextSection.previewImage = null;
    }
    return nextSection;
}

export function trimString(str, len) {
    if (str.length > len) {
        return str.slice(0, len) + '...';
    } else {
        return str;
    }
}

export function move(arr, old_index, new_index) {
    console.log(arr, old_index, new_index);
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

export function clearCase(items) {
    return mapRecursive(items, 'children', (item) => {
        let nextItem = Object.assign({}, item);
        nextItem.category = clearCategory(item.category);
        nextItem.size = clearCategory(item.size);
        nextItem.variant = clearCategory(item.variant);
        nextItem.econom = clearSection(item.econom);
        nextItem.standart = clearSection(item.standart);
        nextItem.premium = clearSection(item.premium);
        return nextItem;
    });
}

String.prototype.replaceAll = function(search, replacement) {
  let target = this;
  if (search.constructor.name === 'Array') {
    return search.reduce((a, c) => a.split(c).join(replacement), target);
  } else {
      return target.split(search).join(replacement);
  }
};

export function clearKey(target, replacer = '') {
  return target.replaceAll(['\'','\`','\"',',','.','-','/','@','*','&','?','|','#','^','(',')','[',']','+',';',':'], replacer);
};

export function transliterate(word, customDict = null) {
  const dict = customDict || {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"'","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"'","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"'","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"'","б":"b","ю":"yu"};

  return word.split('').map(function (char) { 
    return dict[char] || char; 
  }).join("");
};