import {pruneEmpty} from "./utils";
import _ from "lodash";

class Cache {
	constructor(defaultDuration = 30000) {
		this.defaultDuration = defaultDuration;
		this.timeout = null;
		this.store = new Map();
	}

	start() {
		this.timeout = setInterval(this.clearExpiredItems.bind(this), this.defaultDuration);
	}

	stop() {
		clearInterval(this.timeout);
	}

	clearExpiredItems() {
		for (let [key, {expire}] of this.store.entries()) {
			if (expire <= Date.now()) {
				this.store.delete(key);
				console.log(`cache CLEAR: ${key}`);
			}
		}
	}

	set(key, data, duration = null) {
		this.store.set(key, {
			data,
			expire: Date.now() + (duration || this.defaultDuration)
		});
	}

	get(key) {
		if (this.has(key)) {
			return _.cloneDeep(this.store.get(key).data);
		} else return null;
	}

	has(key) {
		return this.store.has(key);
	}
}

const apiPrefix = '/api';
const cache = new Cache(30000);
cache.start();

export const apiGet = async (url, cacheTime = false) => {
	url = (url.substring(0, apiPrefix.length) === apiPrefix) ? url : `${apiPrefix}${url}`;
	if (cacheTime === false) {
		console.log(`cache NO: ${url}`);
		let response = await fetch(url);
		return await response.json();
	} else {
		if (cache.has(url)) {
			console.log(`cache HIT: ${url}`);
			return cache.get(url);
		} else {
			console.log(`cache LOAD: ${url}`);
			let response = await fetch(url);
			let result = await response.json();
			cache.set(url, result, (cacheTime === true) ? cache.defaultDuration : cacheTime);
			return result;
		}
	}
	//return fetch(`${apiPrefix}${url}`).then(response => response.json());
};

export const apiPost = (url, data, method = "POST", ...pruneOptions) => {
	data = pruneEmpty(data, ...pruneOptions);
	return fetch(`${apiPrefix}${url}`, {
		method: method,
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	}).then(response => response.json());
};

/*export const api = {
	get: (url,...args) => this.call('get', url, ...args),
	post: (url,...args) => this.call('post', url, ...args),
	put: (url,...args) => this.call('put', url, ...args),
	patch: (url,...args) => this.call('patch', url, ...args),
	head: (url,...args) => this.call('head', url, ...args),
	delete: (url,...args) => this.call('delete', url, ...args)
};*/